import core from "@/core";
import { EntityType } from "@/types";

class CommentService {
  getList(entityType: EntityType, entityId: number, typeList?: any) {
    return new Promise((resolve, reject) => {
      const params = {
        entityType: entityType,
        entityId: entityId,
      } as any;
      if (typeList != null) {
        params.typeList = typeList.join(",");
      }
      core.http
        .getJson(`/api/comment`, null, params)
        .then((data: any) => {
          if (data.commentList != null) {
            resolve(data.commentList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/comment`, strParams)
        .then((data: any) => {
          if (data.comment != null) {
            resolve(data.comment);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createImage(formData: any) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/comment/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateContent(id: number, content: string) {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify({
        content: content,
      });
      core.http
        .putJson(`/api/comment/${id}`, strParams)
        .then((data: any) => {
          if (data.comment != null) {
            resolve(data.comment);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: string) {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`/api/comment/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new CommentService();
