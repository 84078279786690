
import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import core from "@/core";
import EstimateService from "@/services/estimate/estimate.service";

export default mixins(MixinsComponent).extend({
  name: "PayComponent",
  data: () => ({
    estimate: null as any,
    // estimate: null as any,
    paymentList: [] as any,
  }),
  mounted() {
    this.$nextTick(() => {
      this.getPaymentList();
    });
  },
  methods: {
    getPriceText() {
      const estimate = this.estimate;
      if (estimate != null) {
        if (estimate.paymentYn === "Y") {
          return `견적 금액 ${this.moneyFormat(estimate.totalPrice)}, 결제 ${this.moneyFormat(
            estimate.payment
          )}, <span class="green--text">결제 완료</span>`;
        } else {
          const value = String(Number(estimate.totalPrice) - Number(estimate.payment));
          return `견적 금액 ${this.moneyFormat(estimate.totalPrice)}, 결제 ${this.moneyFormat(
            estimate.payment
          )}, <span class="blue--text">잔금 ${this.moneyFormat(value)}</span>`;
        }
      }
    },
    async getPaymentList() {
      // 결제 목록 조회
      core.loader.show();
      try {
        this.estimate = await EstimateService.get();
        this.paymentList = await EstimateService.getPaymentList();
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    paymentTypeFormat(type) {
      if (type === "CACHE") {
        return "현금";
      } else if (type === "CARD") {
        return "카드";
      } else if (type === "COUPON") {
        return "쿠폰";
      }
      return "";
    },
  },
});
