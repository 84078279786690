
import core from "@/core";
import store from "@/store";
import CommentService from "@/services/comment/comment.service";
import CommentEditModal from "@/modals/comment/CommentEditModal.vue";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { SubscribeType } from "@/core/core-web-socket";
import { EntityType } from "@/types";
import UpdateEvent from "@/models";
import BottomToolbar from "@/components/BottomBar.vue";

export default mixins(MixinsPage).extend({
  name: "CommentComponent",
  components: {
    BottomToolbar,
    CommentEditModal,
  },
  props: {
    entity: {
      type: Object,
      default: () => {
        return {
          type: EntityType.ESTIMATE_SUPPORT,
          id: 0,
          visible: {
            imageHint: false,
          },
        };
      },
    },
  },
  data: () => ({
    width: 0,
    app: store.state.app,
    scrollPercentage: 0,
    formProcessing: false,
    comment: "",
    commentList: [] as any,
    requestDate: null as any,
    bottomBarHeight: 0,
    modal: {
      edit: {
        visible: false,
        params: {
          item: {},
        },
        updateEvent: (null as any) as UpdateEvent | null,
      },
    },
    scrollControl: {
      position: null as string | null,
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(() => {
      this.getCommentList();
      this.subscribe();
      this.width = this.app.size.width - 20;
    });
  },
  updated() {
    console.log("updated");
    if (this.scrollControl.position == "bottom") {
      document.documentElement.scrollTop = document.body.scrollHeight;
      this.scrollControl.position = null;
    }
  },
  watch: {
    "visible.imageHint"(val) {
      console.log("imageHint : ", val);
    },
    "app.size"() {
      this.width = this.app.size.width - 20;
    },
    updated(val) {
      if (val) {
        console.log("updated");
      }
    },
    comment(val, prev) {
      const preLength = (prev.match(/\n/g) || []).length;
      const length = (val.match(/\n/g) || []).length;
      if (preLength != length) {
        const elNodeList = (this.$refs.commentInputWrap as any).getElementsByTagName(
          "textarea"
        ) as any;
        const el = elNodeList[0];
        if (length == 0) {
          el.style.height = "32px";
        } else if (length > 0) {
          el.style.height = 32 + 28 * length + "px";
        }
      }
    },
    "modal.edit.updateEvent"() {
      // const event = this.getComponentUpdateEvent(this.modal.edit);
      // if (event) {
      //   if (event.result === UpdateEventType.RELOAD) {
      //     this.getCommentList();
      //   } else {
      //     console.log("unknown result : ", event.result);
      //   }
      // }
    },
  },
  activated() {
    this.$nextTick(() => {
      this.getCommentList();
      this.subscribe();
    });
  },
  deactivated() {
    this.unsubscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    imageHeight(item) {
      let fileDetail = item.type === "L" ? item.link.fileDetail : item.fileDetail;
      if (fileDetail.imageWidth && fileDetail.imageHeight) {
        const size = this.app.size;
        if (item.selfComment) {
          const cal = (size.width - 87) / fileDetail.imageWidth;
          return fileDetail.imageHeight * cal;
        } else {
          const cal = (size.width - 110) / fileDetail.imageWidth;
          return fileDetail.imageHeight * cal;
        }
      }
    },
    subscribe() {
      const vm = this;
      core.webSocket.subscribe(SubscribeType.COMMENT, {
        entityType: this.entity.type,
        entityId: this.entity.id,
        callback: (event) => {
          const crud = event.crud;
          const item = event.item;
          //console.log("event : ", event);
          if (crud === "C") {
            let exists = false;
            vm.commentList.some((comment, index) => {
              if (comment.id === item.id) {
                core.utils.copy(item, comment);
                vm.updateCommentList(vm.commentList);
                exists = true;
                return true;
              }
            });
            if (!exists) {
              vm.commentList.push(item);
              vm.updateCommentList(vm.commentList);

              if (vm.scrollPercentage > 80 || this.myUser.id == item.user.id) {
                vm.scrollControl.position = "bottom";
              }
            }
          } else if (crud === "U") {
            vm.commentList.some((comment, index) => {
              if (comment.id === item.id) {
                core.utils.copy(item, comment);
                vm.updateCommentList(vm.commentList);
                return true;
              }
            });
          } else if (crud === "D") {
            vm.commentList.some((comment, index) => {
              if (comment.id === item.id) {
                vm.commentList.splice(index, 1);
                return true;
              }
            });
            vm.updateCommentList(vm.commentList);
          } else {
            console.log("invalid crud type ", crud);
          }
        },
      });
    },
    unsubscribe() {
      core.webSocket.unsubscribe(SubscribeType.COMMENT, {
        entityType: this.entity.type,
        entityId: this.entity.id,
      });
    },
    onView(uri: string) {
      window.open(uri, "_blank");
    },
    getChatWrapClass(item: any) {
      let clazz = "";
      if (item.hasParentInfo) {
        clazz += " chat-has-parent";
      }
      if (item.hasChildInfo) {
        clazz += " chat-has-child";
      }
      return clazz;
    },
    editComment(item: any) {
      //console.log("editComment", item);
      this.modal.edit.params.item = item;
      this.modal.edit.visible = true;
    },
    async deleteComment(item: any) {
      //console.log("deleteComment", item);
      const result = await core.alert.show({
        title: "확인",
        body: `선택하신 ${item.type === "I" ? "사진" : "댓글"}을 삭제하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: "삭제",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const apiResult = await CommentService.delete(item.id);
          console.log("apiResult : ", apiResult);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
        // refresh
        //await this.getCommentList();
      }
    },
    async submit(fileDetailId?: any) {
      this.formProcessing = true;

      const params = {
        entityType: this.entity.type,
        entityId: this.entity.id,
      } as any;

      if (fileDetailId == null) {
        if (this.comment == null || this.comment.length == 0) {
          this.formProcessing = false;
          return;
        }
        params.type = "T";
        params.content = this.comment;
      } else {
        params.type = "I";
        params.fileDetailId = fileDetailId;
      }

      try {
        const result = await CommentService.create(params);
        //console.log("result : ", result);
      } catch (e) {
        console.log(e);
      }
      this.comment = "";

      if (fileDetailId == null) {
        this.formProcessing = false;
        //await this.getCommentList();
      }
      this.scrollControl.position = "bottom";
    },
    showImageSelectView() {
      if (this.entity && this.entity.visible) {
        this.entity.visible.imageHint = false;
      }

      if (this.$refs.imageFile) {
        (this.$refs.imageFile as any).click();
      }
    },
    async onChangeImageFile() {
      //console.log("onChangeImageFile");
      this.formProcessing = true;

      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        for (let i = 0; i < length; i++) {
          const file = files[i];
          try {
            const imageFile = (await core.utils.image.getImageBlob(file, 1280)) as any;

            const formData = new FormData();
            formData.append("imageFile", imageFile.blob, imageFile.filename);

            const fileDetailId = await CommentService.createImage(formData);
            await this.submit(fileDetailId);
          } catch (e) {
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }
        (this.$refs.imageFile as any).value = "";
      }
      this.formProcessing = false;

      this.scrollControl.position = "bottom";
    },
    updateCommentList(commentList) {
      // 동일 시간 같은 사용자 글 확인
      let preComment: any = null;
      const user = this.$store.state.auth.user;

      commentList.forEach((comment: any) => {
        comment.hasParentInfo = false;
        comment.hasChildInfo = false;
        if (comment.user != null && comment.user.username !== "customer") {
          comment.user.username = "smartline";
          comment.user.name = "콩콩매트";
        }

        if (preComment != null && !(preComment.type === "S" || preComment.type === "A")) {
          const strCreatedAt = core.date.instance(comment.createdAt).format("YYYYMMDDHHmm");
          const strPreCreatedAt = core.date.instance(preComment.createdAt).format("YYYYMMDDHHmm");
          if (
            comment.user.username == preComment.user.username &&
            strCreatedAt === strPreCreatedAt
          ) {
            comment.hasParentInfo = true;
            preComment.hasChildInfo = true;
          }
        }
        if (comment.hasParentInfo == null) {
          comment.hasParentInfo = false;
          comment.hasChildInfo = false;
        }
        comment.showActionBox =
          !(comment.type === "S" || comment.type === "A") && user.id == comment.user.id;

        if (comment.type === "T" || comment.type === "S" || comment.type === "A") {
          comment.html = core.utils.format.textToHtml(comment.content);
        }
        preComment = comment;

        comment.chatWrapClass = this.getChatWrapClass(comment);
        comment.selfComment = comment.user.id === this.myUser.id;
      });
      this.commentList = commentList;
    },
    async getCommentList() {
      core.loader.show();
      try {
        if (this.requestDate != null) {
          const curDate = new Date();
          const elapsedTimeMillis = curDate.getTime() - this.requestDate.getTime();
          if (elapsedTimeMillis < 1000) {
            return;
          }
        }
        this.requestDate = new Date();

        const commentList = (await CommentService.getList(this.entity.type, this.entity.id)) as any;

        this.updateCommentList(commentList);

        this.commentList = commentList;
        this.scrollControl.position = "bottom";
      } catch (e) {
        console.log(e);
      }
      this.formProcessing = false;
      core.loader.hide();
    },
    dateTimeFormat(strDate: string) {
      return core.date.format.comment(strDate);
    },
  },
});
